import React from "react";
import {
  formContexErrorReducer,
  FormContext,
  FormContextActions,
  formContextReducer,
  FormMode,
  FormRules,
  FormFieldsRefsProvider
} from "@homesusa/form";
import { UserPassword } from "core/interfaces";
export const UserPasswordProvider = ({
  userId,
  children
}: {
  userId?: string;
  children: React.ReactNode;
}): JSX.Element => {
  const [formState, formDispatch] = React.useReducer<
    (
      state: UserPassword,
      action: FormContextActions<UserPassword>
    ) => UserPassword
  >(formContextReducer, Object.assign({ id: userId }));

  // Form errors
  const [formErrors, formErrorsDispatch] = React.useReducer(
    formContexErrorReducer,
    Object.assign({})
  );

  const formRules: FormRules = React.useMemo(
    () => ({
      newPassword: {
        label: "New Password",
        maxLength: 20
      },
      passwordConfirmation: {
        label: "New Password Confirmation",
        maxLength: 20
      }
    }),
    []
  );

  return (
    <FormFieldsRefsProvider>
      <FormContext.Provider
        value={{
          formState,
          formDispatch,
          formMode: FormMode.Update,
          formErrors,
          formRules,
          formErrorsDispatch,
          otherProps: {}
        }}
      >
        {children}
      </FormContext.Provider>
    </FormFieldsRefsProvider>
  );
};
