import React from "react";
import { Grid } from "@mui/material";

import { useHasRole } from "@homesusa/auth";
import {
  Select,
  FormStyled,
  Input,
  EmailInput,
  FormContext,
  PhoneInput
} from "@homesusa/form";
import {
  RoleUser,
  RoleUserLabel,
  Phone,
  PhoneType,
  getOptionsFromEnumAndMap
} from "@homesusa/core";
import { UserCreateFormContext } from "../contexts";

export function UserCreateForm(): JSX.Element {
  const { formDispatch, formState } =
    React.useContext<UserCreateFormContext>(FormContext);
  const { isMlsAdministrator } = useHasRole();
  const options = isMlsAdministrator
    ? getOptionsFromEnumAndMap(Object.values(RoleUser), RoleUserLabel)
    : { [RoleUser.User]: RoleUserLabel.get(RoleUser.User) ?? RoleUser.User };

  const handleChange = (value: unknown, name: string): void => {
    if (!formDispatch) {
      return;
    }
    formDispatch({
      type: "FormChangeByInputName",
      inputName: name,
      payload: value
    });
  };

  const multiselectChange = (value: unknown): void => {
    if (!value) {
      return;
    }
    const newRolesValues = [value];
    handleChange(newRolesValues, "roles");
  };

  const phonesChange = (phone: Phone): void => {
    const { number, phoneType } = phone;
    const phonesValues = formState.phones;
    let newPhonesValues = [];

    if (phonesValues) {
      if (phonesValues.find((phone) => phone.phoneType === phoneType)) {
        newPhonesValues = phonesValues.map((phone) => {
          if (phone.phoneType === phoneType)
            return { ...phone, number: number };
          return phone;
        });
      } else {
        newPhonesValues = [...phonesValues, phone];
      }
    } else {
      newPhonesValues = [phone];
    }
    handleChange(newPhonesValues, "phones");
  };

  return (
    <FormStyled>
      <Grid container>
        <Grid item md={6}>
          <Input name="firstName" />
        </Grid>
        <Grid item md={6}>
          <Input name="lastName" />
        </Grid>
        <Grid item md={6}>
          <EmailInput name="email" />
        </Grid>
        <Grid item md={6}>
          <PhoneInput
            id="businessPhone"
            name="businessPhone"
            label="Business Phone"
            onChange={(value): void =>
              phonesChange({
                number: value,
                phoneType: PhoneType.Business
              })
            }
          />
        </Grid>
        <Grid item md={6}>
          <PhoneInput
            id="mobilePhone"
            name="mobilePhone"
            label="Mobile Phone"
            onChange={(value): void =>
              phonesChange({
                number: value,
                phoneType: PhoneType.Mobile
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Role"
            defaultValue={
              Object.keys(options).length > 0
                ? Object.keys(options)[0]
                : RoleUser.User
            }
            options={options}
            onChange={multiselectChange}
            disabled={Object.keys(options).length == 1}
            required
            removeBlankOption
          />
        </Grid>
      </Grid>
    </FormStyled>
  );
}
