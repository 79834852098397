import React from "react";
import { useNavigate } from "react-router-dom";

import { AppContext } from "@homesusa/layout";
import { deleteUser } from "core/services/user.service";
import { UserContext } from "../contexts";

export const useDeleteUser = (): (() => Promise<void>) => {
  const navigate = useNavigate();
  const { addAlert } = React.useContext(AppContext);
  const { user } = React.useContext(UserContext);

  const onDelete = async (): Promise<void> => {
    return deleteUser(user.id).then(() => {
      addAlert({
        message: "The user was successfully deleted",
        variant: "success"
      });
      navigate("/users");
    });
  };

  return onDelete;
};
