import React from "react";
import UserToolbar from "./user-toolbar.component";
import { UserContext } from "../contexts";
import { UserFormProvider } from "../providers";
import UserForm from "../pages/user-form.component";

function CompanyFormPanel(): JSX.Element {
  const { user } = React.useContext(UserContext);

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <UserFormProvider initialData={user}>
      <UserToolbar />
      <UserForm />
    </UserFormProvider>
  );
}

export default CompanyFormPanel;
