import React from "react";
import { AppContext } from "@homesusa/layout";
import { FormContext } from "@homesusa/form";
import { UpdateUser } from "core/services/user.service";
import { UserContext, UserFormContext } from "../contexts";

export const useUpdateUser = (): (() => Promise<void>) => {
  const { formState } = React.useContext<UserFormContext>(FormContext);
  const { getUserList } = React.useContext(UserContext);
  const { addAlert } = React.useContext(AppContext);
  const onSave = async (): Promise<void> => {
    return UpdateUser(formState).then(() => {
      addAlert({
        message: "The request was successfully updated",
        variant: "success"
      });
      getUserList();
    });
  };

  return onSave;
};
