import React from "react";
import { UserDetail } from "core/interfaces";
import {
  formContexErrorReducer,
  FormContext,
  FormContextActions,
  formContextReducer,
  FormMode,
  FormErrorsButton,
  FormFieldsRefsProvider
} from "@homesusa/form";
import { userRules } from "../rules/user-rules";

function UserFormProvider({
  initialData,
  children
}: {
  children: React.ReactNode;
  initialData?: UserDetail;
}): JSX.Element {
  const [formState, formDispatch] = React.useReducer<
    (state: UserDetail, action: FormContextActions<UserDetail>) => UserDetail
  >(formContextReducer, initialData ?? Object.assign({}));

  // Form errors
  const [formErrors, formErrorsDispatch] = React.useReducer(
    formContexErrorReducer,
    Object.assign({})
  );

  return (
    <FormFieldsRefsProvider>
      <FormContext.Provider
        value={{
          formState,
          formDispatch,
          formMode: FormMode.Update,
          formErrors,
          formErrorsDispatch,
          formRules: userRules,
          otherProps: {}
        }}
      >
        <FormErrorsButton />
        {children}
      </FormContext.Provider>
    </FormFieldsRefsProvider>
  );
}

export { UserFormProvider };
