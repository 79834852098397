import React, { useContext } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import {
  Select,
  FormStyled,
  FormContext,
  Input,
  PhoneInput,
  Switch
} from "@homesusa/form";
import {
  Format,
  RoleUser,
  RoleUserLabel,
  Phone,
  PhoneType
} from "@homesusa/core";
import { useUserProfile } from "@homesusa/auth";
import { AppContext } from "@homesusa/layout";
import { AnimatedContainer, SectionBox } from "core/components";
import SecurityFormAdmin from "./security-form-admin.component";
import { UserPasswordProvider } from "../providers";
import { UserContext, UserFormContext } from "../contexts";

function UserForm(): JSX.Element {
  const user = useUserProfile();
  const { formState, formDispatch } =
    React.useContext<UserFormContext>(FormContext);
  const { user: userContext } = React.useContext(UserContext);

  const {
    appState: { apps }
  } = useContext(AppContext);

  const options = React.useMemo(
    () =>
      Object.values(RoleUser).reduce(
        (a, x) => ({
          ...a,
          [x]: RoleUserLabel.get(x)
        }),
        {}
      ),
    []
  );
  const handleChange = (value: unknown, name: string): void => {
    if (!formDispatch) {
      return;
    }
    formDispatch({
      type: "FormChangeByInputName",
      inputName: name,
      payload: value
    });
  };
  const multiselectChange = (value: unknown): void => {
    const newRolesValues = [value];
    handleChange(newRolesValues, "roles");
  };
  const phonesChange = (phone: Phone): void => {
    const { number, phoneType } = phone;
    const phonesValues = formState.phones;
    let newPhonesValues = [];

    if (phonesValues) {
      if (phonesValues.find((phone) => phone.phoneType === phoneType)) {
        newPhonesValues = phonesValues.map((phone) => {
          if (phone.phoneType === phoneType)
            return { ...phone, number: number };
          return phone;
        });
      } else {
        newPhonesValues = [...phonesValues, phone];
      }
    } else {
      newPhonesValues = [phone];
    }
    handleChange(newPhonesValues, "phones");
  };

  const isPhotographer = userContext.roles?.includes(RoleUser.Photographer);

  const findPhoneByType = (type: string): string | undefined =>
    formState.phones?.find((phone) => phone.phoneType === type)?.number;
  return (
    <AnimatedContainer>
      <FormStyled>
        <Box>
          <Typography sx={{ width: "100%", my: 3 }} variant="h2">
            User Details
          </Typography>
          <Grid container columnSpacing={4}>
            <Grid item xs={6}>
              <SectionBox title="General">
                <Grid container>
                  <Grid item xs={6}>
                    <Input name="firstName" />
                  </Grid>
                  <Grid item xs={6}>
                    <Input name="lastName" />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneInput
                      name="businessPhone"
                      id="businessPhone"
                      label="Business Phone"
                      onChange={(value): void =>
                        phonesChange({
                          number: value,
                          phoneType: PhoneType.Business
                        })
                      }
                      defaultValue={findPhoneByType(PhoneType.Business)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneInput
                      name="mobilePhone"
                      id="mobilePhone"
                      label="Mobile Phone"
                      onChange={(value): void =>
                        phonesChange({
                          number: value,
                          phoneType: PhoneType.Mobile
                        })
                      }
                      defaultValue={findPhoneByType(PhoneType.Mobile)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input label="Username" name="email" disabled />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      label="Created On"
                      required
                      defaultValue={Format.DateTime(formState.sysCreatedOn)}
                      disabled
                    />
                  </Grid>
                </Grid>
              </SectionBox>
            </Grid>
            {user?.isSuperAdmin && (
              <Grid container item xs={6}>
                <Grid item xs={6}>
                  <Box>
                    <SectionBox title="Role Information">
                      <Grid container>
                        <Grid item xs={12}>
                          <Select
                            label="Role"
                            defaultValue={
                              formState.roles ? formState.roles[0] : undefined
                            }
                            options={options}
                            onChange={multiselectChange}
                            removeBlankOption
                            required
                          />
                        </Grid>

                        {isPhotographer && (
                          <Grid item xs={12}>
                            <Button
                              LinkComponent="a"
                              href={`${apps.photoService}/photographers/${userContext.id}`}
                            >
                              Photography Services
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </SectionBox>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <SectionBox title="Email Confirmed">
                      <Grid container>
                        <Grid item xs={12}>
                          <Switch
                            defaultValue={formState.emailConfirmed}
                            name="emailConfirmed"
                            label="Confirmed"
                            disabled={formState.emailConfirmed}
                          />
                        </Grid>
                      </Grid>
                    </SectionBox>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box>
          <Typography sx={{ width: "100%", my: 3 }} variant="h2">
            User Password
          </Typography>
          <UserPasswordProvider userId={formState.id}>
            <SecurityFormAdmin />
          </UserPasswordProvider>
        </Box>
      </FormStyled>
    </AnimatedContainer>
  );
}

export default UserForm;
