import { FormRules } from "@homesusa/form";

export const userRules: FormRules = {
  firstName: {
    required: true,
    label: "First Name"
  },
  lastName: {
    required: true,
    label: "Last Name"
  },
  email: {
    required: true,
    label: "Email"
  }
};
