import React, { Fragment } from "react";
import { Save as SaveIcon } from "@mui/icons-material";

import { FormToolbar as HusaFormToolbar } from "@homesusa/form";
import { useUserProfile } from "@homesusa/auth";
import { SubmitButton } from "@homesusa/layout";
import { UserDetail } from "core/interfaces";
import { DeleteIconButton, InfoIconButton } from "core/components";
import { UserContext } from "../contexts";
import { useDeleteUser, useUpdateUser } from "../hooks";

function UserToolbar(): JSX.Element {
  const currentLoggedUser = useUserProfile();
  const { user } = React.useContext(UserContext);
  const onSave = useUpdateUser();
  const onDelete = useDeleteUser();
  const getUserName = (user: UserDetail): string => {
    if (user) {
      return `User - ${user.firstName} ${user.lastName} (${user.email})`;
    } else return "";
  };

  return (
    <Fragment>
      <HusaFormToolbar>
        <HusaFormToolbar.Left title={getUserName(user)} />
        <HusaFormToolbar.Right>
          <SubmitButton
            onClick={onSave}
            buttonProps={{
              variant: "contained",
              startIcon: <SaveIcon />,
              color: "primary"
            }}
          >
            Save
          </SubmitButton>
          {currentLoggedUser?.isSuperAdmin && (
            <DeleteIconButton
              text="Delete User"
              onClick={onDelete}
              modalBody={<p>Are you sure you want to delete this user?</p>}
            />
          )}
          <InfoIconButton information={user} />
        </HusaFormToolbar.Right>
      </HusaFormToolbar>
    </Fragment>
  );
}

export default UserToolbar;
