import React, { Fragment } from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import { Format } from "@homesusa/core";

export function InfoIconButton({
  information
}: {
  information: {
    sysModifiedOn?: Date;
    createdBy: string;
    modifiedBy?: string;
    sysCreatedOn: Date;
  };
}): JSX.Element {
  const [showModal, setShowModal] = React.useState(false);
  const title = "Entity System Information";
  return (
    <Fragment>
      <Tooltip title={title}>
        <IconButton onClick={(): void => setShowModal(true)} aria-label={title}>
          <InfoIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={showModal}
        onClose={(): void => setShowModal(false)}
        aria-labelledby="info-dialog__title"
      >
        <DialogTitle id="info-dialog__title">{title}</DialogTitle>
        <DialogContent>
          <List>
            {!!information.createdBy && (
              <ListItem>
                <ListItemText
                  primary="Created By"
                  secondary={information.createdBy}
                />
              </ListItem>
            )}
            <ListItem>
              <ListItemText
                primary="Created On"
                secondary={Format.DateTime(information.sysCreatedOn)}
              />
            </ListItem>
            {!!information.modifiedBy && (
              <ListItem>
                <ListItemText
                  primary="Modified By"
                  secondary={information.modifiedBy}
                />
              </ListItem>
            )}
            {!!information.sysModifiedOn && (
              <ListItem>
                <ListItemText
                  primary="Modified On"
                  secondary={Format.DateTime(information.sysModifiedOn)}
                />
              </ListItem>
            )}
          </List>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
