import React from "react";
import { AppContext } from "@homesusa/layout";
import { FormContext } from "@homesusa/form";
import { resetPasswordByAdministrator } from "core/services/user.service";
import { UserPasswordContext } from "../contexts";

export const useResetPasswordByAdministrator = (): ((
  newPassword: string
) => Promise<void>) => {
  const { formState } = React.useContext<UserPasswordContext>(FormContext);
  const { addAlert } = React.useContext(AppContext);

  const useResetPasswordByAdmin = (newPassword: string): Promise<void> =>
    resetPasswordByAdministrator(formState.id, newPassword)
      .then(() => {
        addAlert({
          message: "The password was successfully changed",
          variant: "success"
        });

        return Promise.resolve();
      })
      .catch((e) => {
        addAlert({
          message: e?.response?.data,
          variant: "error"
        });
      });

  return useResetPasswordByAdmin;
};
