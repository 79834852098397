import React from "react";

import {
  FormContext,
  formContextReducer,
  FormContextActions,
  formContexErrorReducer,
  FormMode,
  FormErrorsButton,
  FormFieldsRefsProvider
} from "@homesusa/form";
import { UserCreateForm as IUserCreateForm } from "core/interfaces";
import { userRules } from "../rules/user-rules";

export function UserCreateFormProvider({
  children
}: {
  children: React.ReactNode;
}): JSX.Element {
  // Form state
  const [formState, formDispatch] = React.useReducer<
    (state: IUserCreateForm, action: FormContextActions) => IUserCreateForm
  >(formContextReducer, Object.assign({}));
  // Form errors
  const [formErrors, formErrorsDispatch] = React.useReducer(
    formContexErrorReducer,
    Object.assign({})
  );

  return (
    <FormFieldsRefsProvider>
      <FormContext.Provider
        value={{
          formState: formState,
          formDispatch,
          formMode: FormMode.Update,
          formErrors,
          formErrorsDispatch,
          formRules: userRules,
          otherProps: {}
        }}
      >
        <FormErrorsButton />
        {children}
      </FormContext.Provider>
    </FormFieldsRefsProvider>
  );
}
